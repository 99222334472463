import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_cell = _resolveComponent("van-cell");

  const _component_van_cell_group = _resolveComponent("van-cell-group");

  const _component_van_empty = _resolveComponent("van-empty");

  return _ctx.init ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.list.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
    return _openBlock(), _createBlock(_component_van_cell_group, {
      inset: "",
      style: {
        "margin-top": "20px"
      },
      key: index
    }, {
      default: _withCtx(() => [_createVNode(_component_van_cell, {
        title: '订单号：' + item.order_no,
        value: _ctx.status_text[item.order_status],
        size: "large"
      }, null, 8, ["title", "value"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.goods, (goods, goodsIndex) => {
        return _openBlock(), _createBlock(_component_van_cell, {
          title: goods.goods_name,
          label: '数量：x' + goods.goods_num,
          value: goods.goods_total_price + '元',
          center: "",
          key: goodsIndex
        }, null, 8, ["title", "label", "value"]);
      }), 128)), _createVNode(_component_van_cell, {
        title: '收货人：' + item.receiver_name + ' ' + item.hide_tel,
        label: item.receiver_address
      }, null, 8, ["title", "label"]), item.order_status == 1 && item.express ? (_openBlock(), _createBlock(_component_van_cell, {
        key: 0,
        title: '物流信息：' + item.express.express_name,
        label: '快递单号：' + item.express.express_no
      }, null, 8, ["title", "label"])) : _createCommentVNode("", true)]),
      _: 2
    }, 1024);
  }), 128))])) : (_openBlock(), _createBlock(_component_van_empty, {
    key: 1,
    description: "暂无订单",
    style: {
      "margin-top": "200px"
    }
  }))])) : _createCommentVNode("", true);
}