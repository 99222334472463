import { onMounted, defineComponent, reactive, toRefs } from 'vue';
import { getOrderList } from '@/api/user';
import { Toast } from 'vant';
import 'vant/es/toast/style';
export default defineComponent({
  name: 'Order',
  components: {},

  setup() {
    const state = reactive({
      init: false,
      list: [],
      status_text: ['待发货', '已发货', '已完成']
    });

    const fetchData = async () => {
      const toast = Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const {
        data
      } = await getOrderList();
      toast.clear();
      state.list = data;
      state.init = true;
    };

    onMounted(() => {
      fetchData();
    });
    return { ...toRefs(state)
    };
  }

});